import type { Socket } from "socket.io-client";
import Cookies from "js-cookie";
import { SOCKET_EVENT } from "./index";
import useEventStore from "~/stores/event";
import { EVENT_STATUS } from "~/constants";

export default function useEventSocket(socket: Socket) {
  const route = useRoute();
  const appConfig = useRuntimeConfig().public;
  const eventStore = useEventStore();

  const isConnected = ref(false);
  const eventStatus = computed(() => eventStore.data?.eventStatus);
  const isLive = computed(() => eventStatus.value === EVENT_STATUS.LIVE);
  const isUpcoming = computed(() => eventStatus.value === EVENT_STATUS.UPCOMING);

  watch(eventStatus, () => joinEventRoom(route.params.eventId as string));
  watch(() => route.params.eventId, (newParam, oldParam) => {
    if (newParam !== oldParam)
      leaveEventRoom(oldParam as string);
  });

  function joinEventRoom(eventId: string) {
    if (!(isLive.value || isUpcoming.value) || isConnected.value)
      return;

    const joinResponseCallback = (response: any) => {
      // eslint-disable-next-line no-console
      console.log(`joining event room ${eventId}`, JSON.parse(response));
      if (JSON.parse(response).ok)
        isConnected.value = true;
    };

    const payload = { eventId };

    socket.emit(SOCKET_EVENT.JOIN_EVENT_ROOM, payload, joinResponseCallback);

    socket.on(SOCKET_EVENT.EVENT_URL_CHANGE, (data) => {
      eventStore.isEventStartingSoon = false;
      if (eventStore.data) {
        eventStore.data.source.hd = data.eventUrl;
        eventStore.data.eventStatus = EVENT_STATUS.LIVE as TEventStatus;
        Cookies.remove(`streamError_${eventStore.data?.id}`);
        eventStore.playerError = null;
      } else {
        eventStore.load(route.params.eventId as string);
      }
    });
  }

  function leaveEventRoom(eventId: string) {
    if (!isConnected.value)
      return;

    const leaveResponseCallback = (response: any) => {
      // eslint-disable-next-line no-console
      console.log(`leaving event room ${eventId}`, JSON.parse(response));
      isConnected.value = false;
    };

    const payload = {
      eventId,
      customer: appConfig.APP_CUSTOMER.name_short,
    };

    socket.emit(SOCKET_EVENT.LEAVE_EVENT_ROOM, payload, leaveResponseCallback);
  }
}
