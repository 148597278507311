import type { VideoJsPlayer as IPixellotPlayer } from "@pixellot/web-sdk";
import type { IPlayerState } from "@pixellot/vue-player/src/util/state";
import EventsAPI from "~/services/events";
import TagsAPI from "~/services/tags";
import useUserStore from "~/stores/user";
import SubscriptionAPI from "~/services/subscription";
import {
  COMMERCE_CATEGORY,
  EVENT_MEDIA_TYPE,
  EVENT_STATUS,
  EVENT_TYPE,
  ORDER,
  SORT,
} from "~/constants";

const useEventStore = defineStore("event", {
  state: () => ({
    data: null as null | IModelEvent,
    commerceOptions: [] as IModelCommerce[],
    isEventStartingSoon: false,
    loading: false,
    tags: {
      data: new Map() as Map<string, IModelTag>,
      loading: false,
      activeEditTag: null as null | IModelTag,
      isCreatingTag: false,
      isEditingTag: false,
      error: null as null | string,
    },
    clips: {
      isEditingClip: false,
      isCreatingClip: false,
    },
    channelProgram: {} as IModelLinearProgram,
    canReloadSuggested: false,
    userClips: [] as IModelMediaEvent[],
    userHighlights: [] as IModelMediaEvent[],
    gameHighlights: [] as IModelMediaEvent[],
    playerHighlights: [] as IModelMediaEvent[],
    scoreStats: null as null | IEventScoreStats,
    gameStats: null as null | IEventGameStats,
    player: null as null | IPixellotPlayer,
    playerState: null as null | IPlayerState,
    playerError: null as null | string,
  }),

  getters: {
    activePPVTicket: (state) => {
      const userStore = useUserStore();

      return userStore.ppvTickets.find(t => state.commerceOptions.find(c => t.eventId === c.eventId));
    },
  },

  actions: {
    async load(id: string, type?: TEventType) {
      const userStore = useUserStore();
      const appConfig = useRuntimeConfig().public;
      const isEnableMatchStats = appConfig.APP_CUSTOMER.enable_match_stats;
      const isGameHighlightEnabled = appConfig.APP_CUSTOMER.enable_game_highlights;
      const isPlayerHighlightsEnabled = appConfig.APP_CUSTOMER.enable_player_highlights;

      this.loading = true;

      this.data = await this.loadEvent(id, type);
      if (!this.data) {
        showError({ statusCode: 404, data: { context: EVENT_TYPE.EVENT } });
        return;
      }

      const eventId = this.data.eventId;
      const referenceId = this.data.referenceId;
      const { commerceCoverage } = this.data;
      const isCommerce = (
        commerceCoverage.includes(COMMERCE_CATEGORY.PPV)
        || commerceCoverage.includes(COMMERCE_CATEGORY.SUBSCRIPTION)
      );
      const isStatsAvailableForUser = eventId
        && isEnableMatchStats
        && this.data.isEventAvailableForUser
        && this.data.isEventAvailableInUserRegion
        && this.data.isEnabledVidswapStats;

      if (this.data.eventStatus === EVENT_STATUS.CHANNEL) {
        const loadCommerceOptionsPromise = isCommerce ? this.loadCommerceOptions(this.data) : Promise.resolve([]);
        const loadChannelProgramPromise = this.loadChannelProgram(this.data);
        const [commerceOptions, channelProgram] = await Promise.all([
          loadCommerceOptionsPromise,
          loadChannelProgramPromise,
        ]);
        this.commerceOptions = commerceOptions;
        this.channelProgram = channelProgram;

        this.canReloadSuggested = false;
        this.loading = false;
        return;
      }

      const loadClipsPromise = eventId && userStore.isAuthenticated ? this.loadUserMedia(eventId, EVENT_TYPE.USER_CLIP) : Promise.resolve([]);
      const loadUserHighlightsPromise = eventId
      && userStore.isAuthenticated
       && isGameHighlightEnabled
        ? this.loadUserMedia(eventId, EVENT_TYPE.USER_HIGHLIGHT)
        : Promise.resolve([]);
      const loadStatsPromise = isStatsAvailableForUser ? this.loadStats(eventId) : Promise.resolve({ scores: null, overview: null });
      const loadTagsPromise = this.loadTags(referenceId, this.data);
      const loadGameHighlightsPromise = eventId && isGameHighlightEnabled ? this.loadHighlights(eventId) : Promise.resolve([]);
      const loadPlayerHighlightsPromise = eventId && isPlayerHighlightsEnabled ? this.loadPlayerHighlights(eventId) : Promise.resolve([]);
      const loadCommerceOptionsPromise = isCommerce ? this.loadCommerceOptions(this.data) : Promise.resolve([]);

      const [userClips, userHighlights, loadedTags, gameHighlights, playerHighlights, commerceOptions, stats] = await Promise.all([
        loadClipsPromise,
        loadUserHighlightsPromise,
        loadTagsPromise,
        loadGameHighlightsPromise,
        loadPlayerHighlightsPromise,
        loadCommerceOptionsPromise,
        loadStatsPromise,
      ]);

      this.userClips = userClips;
      this.userHighlights = userHighlights;
      this.tags.data = new Map(loadedTags.map(tag => [tag.id, tag]));
      this.gameHighlights = gameHighlights;
      this.playerHighlights = playerHighlights;
      this.commerceOptions = commerceOptions;
      this.scoreStats = stats.scores;
      this.gameStats = stats.overview;

      this.canReloadSuggested = false;
      this.loading = false;
    },
    async loadEvent(id: string, type?: TEventType) {
      let event = null;
      try {
        event = await EventsAPI.loadById(id, type);
      } catch (err) {
        console.error(err);
      }
      return event;
    },
    async loadTags(id: string, event: IModelEvent) {
      const userStore = useUserStore();
      let tags: IModelTag[] = [];
      try {
        if (userStore.isAuthenticated) {
          // TODO: Move out global tags when BE will remove authentication from there
          const [globalTags, userTags] = await Promise.all([
            TagsAPI.load({ id, event, global: true }),
            TagsAPI.load({ id, event, global: false }),
          ]);

          tags = tags.concat(userTags, globalTags);
        }
      } catch (err) {
        if (err instanceof Error)
          this.tags.error = err.message;
        else this.tags.error = String(err);
      }
      return tags;
    },
    loadUserMedia(
      eventId: string,
      user_media_type: TUserMediaType,
    ): Promise<IModelMediaEvent[]> {
      return EventsAPI.userMedia
        .load({ page: 0, size: 24, order: ORDER.desc, sort: SORT.added, event_id: [eventId], media_type: [user_media_type] })
        .then(response => response.content.entries)
        .catch((error) => {
          console.error(`Failed to load User ${user_media_type}`, error);
          return [];
        });
    },
    loadHighlights(eventId: string): Promise<IModelMediaEvent[]> {
      return EventsAPI.userMedia
        .loadHighlights({ page: 0, size: 24, order: ORDER.desc, event_id: [eventId] })
        .then(response => response.content.entries)
        .catch((error) => {
          console.error("Failed to load Game Highlights", error);
          return [];
        });
    },
    loadPlayerHighlights(eventId: string): Promise<IModelMediaEvent[]> {
      return EventsAPI.userMedia
        .loadHighlights({ page: 0, size: 24, order: ORDER.desc, event_id: [eventId], type: [EVENT_MEDIA_TYPE.PLAYER] })
        .then(response => response.content.entries)
        .catch((error) => {
          console.error("Failed to load Player Highlights", error);
          return [];
        });
    },
    loadStats(eventId: string): Promise<IEventStats> {
      return EventsAPI.stats
        .getEventScoreStatsById(eventId)
        .catch((error) => {
          console.error("Failed to load Score Stats", error);
          return { scores: null, overview: null };
        });
    },
    loadCommerceOptions(event: IModelEvent) {
      const id = (event.eventType === EVENT_TYPE.EVENT
          || event.eventType === EVENT_TYPE.CLIP
          || event.eventStatus === EVENT_STATUS.CHANNEL)
        ? event.id
        : event.eventId;

      if (!id)
        return [];

      return SubscriptionAPI
        .loadVodCommerceOptions(id)
        .catch((error) => {
          console.error("Failed to load commerce Options", error);
          return [];
        });
    },
    loadChannelProgram(event: IModelEvent) {
      if (!event.source.epg)
        return {} as IModelLinearProgram;

      return EventsAPI.loadChannelProgram(event.source.epg).catch((error) => {
        console.error("Failed to load channel program", error);
        return {} as IModelLinearProgram;
      });
    },
    reset(): void {
      this.data = null;
      this.loading = false;
      this.isEventStartingSoon = false;
      this.tags.data = new Map() as Map<string, IModelTag>;
      this.tags.loading = false;
      this.tags.activeEditTag = null as null | IModelTag;
      this.tags.isCreatingTag = false;
      this.tags.isEditingTag = false;
      this.tags.error = null as null | string;
      this.clips.isCreatingClip = false;
      this.clips.isEditingClip = false;
      this.channelProgram = {} as IModelLinearProgram;
      this.userClips = [];
      this.userHighlights = [];
      this.gameHighlights = [];
      this.playerHighlights = [];
      this.commerceOptions = [];
      this.scoreStats = null;
      this.gameStats = null;
      this.player = null;
      this.playerState = null;
      this.playerError = null;
    },
  },
});

export default useEventStore;

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useEventStore, import.meta.hot));
